import {
  AuthResponse,
  BrandList,
  PaymentTerm,
  UserInitData,
  UsersFetchResponse,
} from "../types/types";

const authHeader = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

const BASE_URL = process.env.REACT_APP_BASE_URL!;

export const login = async (
  user: string,
  pass: string
): Promise<AuthResponse> => {
  const url = `${BASE_URL}/auth/login`;
  const body = JSON.stringify({ user, pass });
  const response = await fetch(url, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const twofaLogin = async (
  authToken: string,
  twofaToken: string
): Promise<AuthResponse> => {
  const url = `${BASE_URL}/auth/totp`;
  const body = JSON.stringify({ token: twofaToken });
  const response = await fetch(url, {
    method: "POST",
    body,
    headers: authHeader(authToken),
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const getBrandList = async (token: string): Promise<BrandList[]> => {
  const response = await fetch(`${BASE_URL}/brandlist`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const getUsers = async (
  token: string,
  page = 1,
  search = "",
  status = "",
  sort = ""
): Promise<UsersFetchResponse> => {
  const searchParam = search ? `&search=${search}` : "";
  const statusParam = status ? `&status=${status}` : "";
  const sortParam = sort ? `&sort=${sort}` : "";
  const response = await fetch(
    `${BASE_URL}/view?page=${page}${searchParam}${statusParam}${sortParam}`,
    {
      headers: authHeader(token),
    }
  );
  if (!response.ok) {
    throw new Error();
  }
  const data: UsersFetchResponse = await response.json();
  const mappedUsers = data.users.map((user) => ({
    ...user,
    processing: false,
  }));
  return { ...data, users: mappedUsers };
};

export const getUserDetail = async (token: string, id: string) => {
  const url = `${BASE_URL}/user/${id}`;
  const response = await fetch(url, {
    method: "GET",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const markUserPaid = async (
  token: string,
  id: string,
  term: PaymentTerm
): Promise<boolean> => {
  const url = `${BASE_URL}/${id}/payment`;
  const body = JSON.stringify({ term });
  const response = await fetch(url, {
    method: "PATCH",
    body,
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const activateUser = async (
  token: string,
  id: string
): Promise<UserInitData> => {
  const url = `${BASE_URL}/${id}/activate`;
  const response = await fetch(url, {
    method: "PATCH",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const deactivateUser = async (
  token: string,
  id: string
): Promise<{ id: string; name: string }> => {
  const url = `${BASE_URL}/${id}/deactivate`;
  const response = await fetch(url, {
    method: "PATCH",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const changeUserPlanConfiguration = async (
  token: string,
  id: string,
  plan: string,
  brands: string[]
): Promise<boolean> => {
  const url = `${BASE_URL}/${id}/configure-plan`;
  const body = JSON.stringify({ plan, brands });
  const response = await fetch(url, {
    method: "PATCH",
    body,
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};
